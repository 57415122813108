import method from '@/views/method/method';
<template>
  <div>
    <!-- bg -->
    <div class="bg_gray"></div>
    <div class="center">
      <headbox
        title="定价记录"
        bgColor="#fff"
        righbox="上门取货"
        righturl="pickUp"
        :isbackperson="true"
      ></headbox>
      <div class="centerbox">
        <div class="flex-around tab_title rel">
          <span
            v-for="items in list"
            :key="items.index"
            :class="nowIndex === items.index ? 'active' : ''"
            @click="activebox(items.index, items.type)"
          >
            {{ items.name }}
          </span>
        </div>
        <div class="">
          <!-- 消息盒子 -->
          <div class="loadingbox" v-if="haloadng">
            <van-loading size="36px"></van-loading>
          </div>
          <div v-if="!haloadng">
            <template v-if="!haveDate">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
            </template>
            <template v-if="haveDate">
              <div ref="scroll">
                <div
                  v-for="(item, index) in productlist"
                  :key="item.oid"
                  class="msgbox"
                  :data-index="index"
                >
                  <h2 class="flex-between h2">
                    <div>{{ item.goods[0].name }}</div>
                    <!-- 买入订单状态,ALL:全部卖出订单 SUSPENSE:待审核,FAIL:未通过,UNDELIVERED:待送达||待发货,CANCEL:已取消,DONE:已完成 -->
                    <div v-if="item.status == 'SUSPENSE'" class="statusbox">
                      待审核
                    </div>
                    <div v-if="item.status == 'FAIL'" class="statusbox">
                      未通过
                    </div>
                    <div
                      v-if="item.status == 'UNDELIVERED'"
                      class="onstatusbox"
                    >
                      待发货
                    </div>
                    <div v-if="item.status == 'CANCEL'" class="overstatusbox">
                      已取消
                    </div>
                    <div v-if="item.status == 'DONE'" class="overstatusbox">
                      已完成
                    </div>
                  </h2>
                  <div class="flex">
                    <div>定价价格：</div>
                    <div>{{ item.goods[0].price.toFixed(2) }}元/克</div>
                  </div>
                  <div class="flex">
                    <div>定价克重：</div>
                    <div>{{ item.goods[0].weight.toFixed(2) }}克</div>
                  </div>
                  <div class="flex">
                    <div>定价时间：</div>
                    <div>{{ item.time | formatDate_ }}</div>
                  </div>
                  <div class="flex-between">
                    <div><i class="el-icon-more"></i></div>
                    <div class="flexs">
                      <div
                        class="gosendproduct"
                        @click="gosendproduct"
                        v-if="item.status == 'UNDELIVERED'"
                      >
                        去发货
                      </div>
                      <div
                        class="yellowmin"
                        @click="
                          pricedetails(
                            item.goods[0],
                            item.status,
                            item.oid,
                            item.time
                          )
                        "
                      >
                        定价详情
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 加载更多 -->
                <center v-if="!isLoadedover" class="loadingcenter">
                  玩命加载中...
                </center>
                <div v-if="isInitFont">
                  <center v-if="isLoadedover" class="loadingcenter">
                    已经加载完毕
                  </center>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import { formatDate_ } from "@/utils/filters";
import * as PricingRecordspage from "@/utils/api";
export default {
  data() {
    return {
      haloadng: true,
      // 是否加载数据
      adddata: false,
      enableLoadMore: true,
      haveDate: false,
      productlist: [],
      list: [
        { index: 1, name: "全部", type: "ALL" },
        { index: 2, name: "待审核", type: "SUSPENSE" },
        { index: 3, name: "未通过", type: "FAIL" },
        { index: 4, name: "待发货", type: "UNDELIVERED" },
        { index: 5, name: "已完成", type: "DONE" },
        { index: 6, name: "已取消", type: "CANCEL" }
      ],
      nowIndex: 1,
      // 当前定价记录的类型
      thantType: "ALL",
      indexPage: 0,
      // 是否显示加载更多的文字
      isLoaded: false,
      // 加载完成了
      isLoadedover: false,
      // 初始化显示是否加载完成
      isInitFont: true
    };
  },
  filters: {
    formatDate_
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollFun);
  },
  mounted() {
    this.getData();
    setTimeout(() => {
      window.addEventListener("scroll", this.onScrollFun);
    }, 500);
  },
  methods: {
    // 定价详情
    pricedetails(productx, type, oid, time) {
      localStorage.setItem("product", JSON.stringify(productx));
      this.$router.push({
        name: "pricingDetails",
        query: { tyPe: type, Oid: oid, time: time }
      });
    },
    // 去发货
    gosendproduct() {
      this.$router.push({ name: "pickUp" });
    },
    onScrollFun() {
      const than = this;
      //可滚动容器的高度
      const innerHeight = this.$refs.scroll.clientHeight;
      //屏幕尺寸高度
      const outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      const scrollTop = document.documentElement.scrollTop;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      // console.log(innerHeight + " " + outerHeight + " " + scrollTop);
      if (innerHeight <= outerHeight + scrollTop) {
        if (this.isLoaded) {
          return false;
        }
        than.adddata = true;
        than.isLoaded = true;
        than.indexPage++;
        than.getData(than.thantType);
        //加载更多操作
        // for(var i=0;i<10;i++){
        //   var now=Date.now();
        //   // 更新数据; 在items数组中新增10条数据
        //   this.items.push({
        //     id:now,
        //     name:'商品'+now,
        //     price:now.toString().substr(10,3)
        //   });
        // }
      }
    },
    getData(type) {
      const type_ = type || "ALL";
      PricingRecordspage.mortgageApi(type_, this.indexPage).then(res => {
        console.log('res',res);
        this.haloadng = false;
        // 加载更多
        if (this.adddata) {
          if (res.content.length <= 3) {
            this.isLoaded = true;
            this.isLoadedover = true;
            const newarr_ = [...this.productlist, ...res.content];
            this.productlist = newarr_;
          } else {
            const newarr = [...this.productlist, ...res.content];
            this.productlist = newarr;
            this.isLoaded = false;
          }
        } else {
          if (res.content.length <= 0) {
            this.haveDate = false;
          } else {
            // 初始化数据
            this.productlist = res.content;
            this.haveDate = true;
            this.isLoadedover = true;
            this.isInitFont = false;
          }
        }
      });
    },
    activebox(index, type) {
      const than = this;
      if (this.nowIndex == index) {
        return false;
      }
      this.productlist = [];
      than.adddata = false;
      than.isLoaded = false;
      than.isLoadedover = false;
      than.thantType = type;
      than.indexPage = 0;
      than.getData(type);
      than.nowIndex = index;
    }
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.loadingbox {
  text-align: center;
  margin: 150px auto;
}
.loadingcenter {
  font-size: 28px;
  margin: 20px 0;
}
.custom-image {
  margin-top: 150px;
}
.center {
  position: relative;
  z-index: 2;
}
.gosendproduct {
  background: #ffb606;
  color: #fff;
  border-radius: 32px;
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  margin-right: 26px;
  font-size: 28px;
}
.flexs {
  display: flex;
}
.bg_gray {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f7f7f7;
}
.centerbox {
  border-top: 1px solid #e6e6e6;
  position: relative;
  z-index: 2;
}
.statusbox {
  color: #2e9bfb;
  background: #d0eeff;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.onstatusbox {
  color: #fc4e7f;
  background: #fcdae2;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.overstatusbox {
  color: #999999;
  background: #f0f0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.flex-between {
  align-items: center;
  i {
    font-size: 48px;
    color: #cccccc;
  }
}

.yellowmin {
  color: #ffb606;
  border: 1px solid #ffb606;
  border-radius: 30px;
  font-size: 28px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 30px;
}
.msgbox {
  background: #fff;
  padding: 0 30px 30px;
  width: 690px;
  font-size: 28px;
  border-radius: 12px;
  box-shadow: 0 0 0 3px #ececec;
  margin: 25px auto 30px;

  h2 {
    font-size: 32px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  .flex {
    margin-bottom: 30px;
    div:nth-child(1) {
      color: #cccccc;
    }
    div:nth-child(2) {
      color: #333333;
    }
  }
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 26px;
  span {
    padding: 20px 0px 10px;
  }
  .active {
    position: relative;
    // color:#FFB606;
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 125px;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
::v-deep{
      .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
</style>
