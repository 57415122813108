var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("headbox", {
          attrs: {
            title: "定价记录",
            bgColor: "#fff",
            righbox: "上门取货",
            righturl: "pickUp",
            isbackperson: true
          }
        }),
        _c("div", { staticClass: "centerbox" }, [
          _c(
            "div",
            { staticClass: "flex-around tab_title rel" },
            _vm._l(_vm.list, function(items) {
              return _c(
                "span",
                {
                  key: items.index,
                  class: _vm.nowIndex === items.index ? "active" : "",
                  on: {
                    click: function($event) {
                      return _vm.activebox(items.index, items.type)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(items.name) + " ")]
              )
            }),
            0
          ),
          _c("div", {}, [
            _vm.haloadng
              ? _c(
                  "div",
                  { staticClass: "loadingbox" },
                  [_c("van-loading", { attrs: { size: "36px" } })],
                  1
                )
              : _vm._e(),
            !_vm.haloadng
              ? _c(
                  "div",
                  [
                    !_vm.haveDate
                      ? [
                          _c("van-empty", {
                            staticClass: "custom-image",
                            attrs: {
                              image: require("@/assets/images/pic_no information@2x.png"),
                              description: "暂无数据!"
                            }
                          })
                        ]
                      : _vm._e(),
                    _vm.haveDate
                      ? [
                          _c(
                            "div",
                            { ref: "scroll" },
                            [
                              _vm._l(_vm.productlist, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.oid,
                                    staticClass: "msgbox",
                                    attrs: { "data-index": index }
                                  },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "flex-between h2" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(item.goods[0].name))
                                        ]),
                                        item.status == "SUSPENSE"
                                          ? _c(
                                              "div",
                                              { staticClass: "statusbox" },
                                              [_vm._v(" 待审核 ")]
                                            )
                                          : _vm._e(),
                                        item.status == "FAIL"
                                          ? _c(
                                              "div",
                                              { staticClass: "statusbox" },
                                              [_vm._v(" 未通过 ")]
                                            )
                                          : _vm._e(),
                                        item.status == "UNDELIVERED"
                                          ? _c(
                                              "div",
                                              { staticClass: "onstatusbox" },
                                              [_vm._v(" 待发货 ")]
                                            )
                                          : _vm._e(),
                                        item.status == "CANCEL"
                                          ? _c(
                                              "div",
                                              { staticClass: "overstatusbox" },
                                              [_vm._v(" 已取消 ")]
                                            )
                                          : _vm._e(),
                                        item.status == "DONE"
                                          ? _c(
                                              "div",
                                              { staticClass: "overstatusbox" },
                                              [_vm._v(" 已完成 ")]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [_vm._v("定价价格：")]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            item.goods[0].price.toFixed(2)
                                          ) + "元/克"
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [_vm._v("定价克重：")]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            item.goods[0].weight.toFixed(2)
                                          ) + "克"
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [_vm._v("定价时间：")]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate_")(item.time)
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "flex-between" }, [
                                      _vm._m(0, true),
                                      _c("div", { staticClass: "flexs" }, [
                                        item.status == "UNDELIVERED"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "gosendproduct",
                                                on: { click: _vm.gosendproduct }
                                              },
                                              [_vm._v(" 去发货 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "yellowmin",
                                            on: {
                                              click: function($event) {
                                                return _vm.pricedetails(
                                                  item.goods[0],
                                                  item.status,
                                                  item.oid,
                                                  item.time
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" 定价详情 ")]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              }),
                              !_vm.isLoadedover
                                ? _c(
                                    "center",
                                    { staticClass: "loadingcenter" },
                                    [_vm._v(" 玩命加载中... ")]
                                  )
                                : _vm._e(),
                              _vm.isInitFont
                                ? _c(
                                    "div",
                                    [
                                      _vm.isLoadedover
                                        ? _c(
                                            "center",
                                            { staticClass: "loadingcenter" },
                                            [_vm._v(" 已经加载完毕 ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-more" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }